@import url('https://fonts.googleapis.com/css?family=Raleway:200,400,700')

// Color Variables
$dark-blue: #252934
$bright-blue: #00a1c9
$pink: #E31B6D
$white: white
$light-grey: #f5f5f5
$input-grey: #ced4da
$dark-grey: #696969
$input-black: #495057
$error-red: #dc3545

// Sizing Variables
$standard-font-weight: 1.25rem
$standard-line-height: 1.5

// Grid Screen Sizes
$screen-xl: 1200px
$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px

body
  margin: 0
  font-family: Raleway
  text-align: center


header
  text-align: center
  img
    height: 20rem
    margin-left: auto
    margin-right: auto

input
  border: 1px solid $input-grey
  border-radius: .25rem
  height: 1.85rem
  line-height: $standard-line-height
  color: $input-black
  font-size: $standard-font-weight
  padding: .375rem .75rem
  width: 100%
  outline-color: $input-grey
.input-error
  border-color: $error-red
  outline-color: $error-red
.input-error-label
  font-size: .75em
  color: $error-red


button
  text-align: center
  border: 1px solid transparent
  border-radius: .25rem
  line-height: $standard-line-height
  font-size: $standard-font-weight
  padding: .375rem .75rem
  vertical-align: middle
  background-color: $dark-blue
  color: $white
  width: 100%
button:disabled
  background-color: $dark-grey

.btn-loading::after
  content: ""
  position: absolute
  width: 1.5rem
  height: 1.5rem
  left: auto
  right: auto
  margin: auto auto auto 0.5rem
  border: 4px solid transparent
  border-top-color: $pink
  border-radius: 50%
  animation: button-loading-spinner 1s ease infinite

@keyframes button-loading-spinner
  from
    transform: rotate(0turn)
  to
    transform: rotate(1turn)

.highlighted
  color: $bright-blue

.RepoForm
  margin-left: auto
  margin-right: auto
  text-align: center
  .repo-input
    margin-bottom: .5rem
    width: 45%
    min-width: 20rem
    text-align: center
  .repo-fetch-btn
    width: 45%
    min-width: 20rem
    margin-top: .5rem

.RepoList
  margin-top: 3rem
  margin-left: auto
  margin-right: auto
  text-align: center
  width: 20%
  min-width: 20rem
  .Repo
    border: 2px solid $dark-blue
    margin-bottom: 3rem
    border-radius: .25rem
    width: 100%
    text-align: left
    .repo-title
      font-size: 1.2rem
      font-weight: 700
      padding-left: 1rem
    .repo-wording
      padding-left: 1rem
      font-size: 1rem
      font-weight: 400
      padding-bottom: 1rem
    .missing
      color: $dark-grey
    .repo-header
      background-color: $dark-blue
      color: $white
      padding: 1rem 0 1rem
      margin-bottom: .5rem
      float: left
      width: 100%
    .repo-body
      img
        height: 20px
        vertical-align: center
      .repo-stars
        margin-top: 2rem
        color: $pink
      .repo-description
        padding-top: 1rem
      .repo-language
        margin-bottom: 1rem


// Styles Based On Screen Sizes
@media (min-width: $screen-md)
  header
    img
      height: 35rem
// @media (min-width: $screen-lg)
// @media (min-width: $screen-xl)
